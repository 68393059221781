import React from 'react'
import { graphql } from 'gatsby'
import NotFound from '../components/NotFound'

export const query = graphql`
  query {
    errorIcon: file(
      name: { eq: "error_cardboard" }
      relativeDirectory: { eq: "404" }
    ) {
      childImageSharp {
        fixed(width: 304) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default React.memo(({ data }) => <NotFound errorIcon={data.errorIcon} />)
