import React from 'react'
import { Layout } from '../Shared'
import { IrisImg } from '../Shared'
import './NotFound.global.scss'

const NotFoundView = ({ errorIcon }) => (
  <div className="not-found">
    <div>
      <IrisImg file={errorIcon} alt="Error Icon" className="error-icon" />
    </div>
    <h1>404</h1>
    <p className="oops">
      Oops! We can&#39;t seem to find the page you&#39;re looking for.
    </p>
  </div>
)

export default props => (
  <Layout>
    <NotFoundView {...props} />
  </Layout>
)
